/***** Vuexy Wizard *****/

.vx-wizard {
  box-shadow: none !important;
  display: table;
  width: 100%;
  &.nav.nav-tabs {
    .step-wrapper.nav-item {
      display: table-cell;
      width: auto;
      .step.nav-link {
        transform: translateY(0) !important;
        text-align: center !important;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .step-text {
          border-radius: 50%;
          font-size: 1.5rem;
          z-index: 2;
          height: 80px;
          width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          background: $white;
        }
        &.active {
          .step-text {
            background-color: $primary;
            color: $white;
          }
          &:after {
            background: $primary !important;
            box-shadow: none !important;
            height: 3px;
            top: 50% !important;
            z-index: -1;
            width: 50%;
          }
        }
        &.done {
          .step-text {
            border-color: $primary !important;
          }
          &:before,
          &:after {
            background: $primary !important;
            box-shadow: none !important;
            height: 3px;
            top: 50% !important;
            z-index: -1;
            width: 50%;
            content: "";
            position: absolute;
            top: 50%;
            width: 45.5%;
          }
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }
        .step-text-color{
          color: $gray-600;
          font-size: 12px;
        }
        &.activeText {
          .step-text-color {
            color: $primary;
          }
        }
        &.doneText{
          .step-text-color {
            color: $primary;
          }
        }
        
        &:not(.active) {
          .step-text {
            border: 3px solid $gray-600;
            color: $gray-600;
          }
        }
      }
    }
  }
}
