// ================================================================================================
//  File Name: components.scss
//  Description: Common components file to includ all theme specific custom components.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy React Admin Template
//  Version: 1.0
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Variables
// ------------------------------

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "core/variables/components-variables";

@import "core/mixins/transitions";
// For alert box shadow
// Perfect Scrollbar
// Core
// ------------------------------
// layouts
@import "core/layouts/content";
@import "core/layouts/sidebar";
@import "core/layouts/footer";
// menu
@import "core/menu/navigation";
@import "core/menu/horizontal-menu.scss";

// Components
// ------------------------------
@import "components/helper";
@import "components/avatar";
@import "components/search";
@import "components/chart";
@import "components/bootstrap-social";
@import "components/demo"; // Plugins demo, remove in real project
@import "components/customizer"; // Customizer demo, remove in real project
@import "components/chips";
@import "components/divider";
@import "components/wizard";
@import "components/autocomplete.scss";
@import "components/app-loader.scss";
@import "components/chat-widget.scss";

// Plugins
// ------------------------------
@import "plugins/ui/breakpoints";
// TODO:A: Set this scss page specific
@import "plugins/forms/tags/basic-input";
@import "plugins/tables/datatables";

@import "./themes/dark-layout.scss";
@import "./themes/semi-dark-layout.scss";
@import "./themes/semi-bma-layout.scss";

//--- Text Error ---
.error {
  color: #dc3545;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
  &::before {
    display: inline;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 2px;
  }
}
.select-error {
  border: 1px #dc3545 solid;
  border-radius: 5px;
}

.text-success-lighten-1 {
  color: #267659 !important;
}

.bg-success-lighten-1 {
  background-color: #96C43C !important;
}

input.flatpickr-input:disabled{
  background-color: #fafafa !important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: unset !important;
  background-image:  unset !important;
  background-repeat:  unset !important;
  background-position:  unset !important;
  background-size:  unset !important;
}